<template>
  <div class="betperesult" v-if="selectedGame && selectedGame.game_id">
    <div v-if="selectedGame.hasBets && areBetsVisible" class="betperesult-general">
      <BetPerResult :await-for-bets="awaitForBets" :parlay-bets="parlayBets" />
    </div>
    <div v-if="colorsConfig.show_logo && showLogos" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div class="betperesult-clashes-general">
      <div class="betperesult-clashes-general__lastclashes">
        <BetPerResultClashes :game-id="gameId" />
      </div>
      <div class="betperesult-clashes-general__lastgames">
        <BetPerResultLast5Games :game-id="gameId" />
      </div>
    </div>
    <div v-if="selectedGame.hasStatistics && colorsConfig.show_logo && showLogos" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div v-if="selectedGame.hasStatistics" class="betperesult-facetoface">
      <BetFaceToFace :game-data="faceToFaceData" />
    </div>
    <div v-if="selectedGame.hasStatistics && colorsConfig.show_logo && showLogos" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div v-if="selectedGame.hasStatistics" class="betperesult-players">
      <BetFeaturedPlayers />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BetResults',
  components: {
    BetPerResult: () => import('@/components/Sections/GolTipster-v2/General/BetPerResult'),
    BetFaceToFace: () => import('@/components/Sections/GolTipster-v2/General/BetFaceToFace'),
    BetPerResultClashes: () => import('@/components/Sections/GolTipster-v2/General/BetPerResultClashes'),
    BetPerResultLast5Games: () => import('@/components/Sections/GolTipster-v2/General/BetPerResultLast5Games'),
    BetFeaturedPlayers: () => import('@/components/Sections/GolTipster-v2/General/BetFeaturedPlayers'),
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  props: {
    awaitForBets: {
      type: Boolean,
      default: false,
    },
    parlayBets: {
      type: Object,
      default: () => ({}),
    },
    showLogos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      areBetsVisible: false,
    };
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame', 'refreshIntervalCounter']),
    ...mapState('profileGeneral', ['colorsConfig']),
    gameId() {
      return this.selectedGame.game_id || 0;
    },
    faceToFaceData() {
      const faceToFaceData = {
        seasonId: 0,
        homeTeam: null,
        awayTeam: null,
      };
      if (this.selectedGame.seasonId) {
        faceToFaceData.seasonId = this.selectedGame.seasonId;
        faceToFaceData.homeTeam = this.selectedGame.homeTeam;
        faceToFaceData.awayTeam = this.selectedGame.awayTeam;
      }
      return faceToFaceData;
    },
  },
  watch: {
    refreshIntervalCounter: {
      immediate: true,
      handler() {
        this.setAreBetVisible();
      },
    },
    selectedGame() {
      this.setAreBetVisible();
    },
  },
  methods: {
    setAreBetVisible() {
      const betSectionsLimit = Date.now() + 60 * 1000;
      const gameDateTime = new Date(this.selectedGame.date_time_utc).valueOf();
      this.areBetsVisible = gameDateTime >= betSectionsLimit;
    },
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss">
.betperesult {
  & > div {
    margin: 1rem 0 !important;

    &:first-child {
      margin-top: 0 !important;
    }
  }
}
.betperesult-clashes-general {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: inline-flex;
  margin-top: 28px;
  @media screen and (max-width: 563px) {
    display: inline-block;
  }
  &__lastclashes {
    width: 390px;
    height: 100%;
    @media screen and (max-width: 963px) {
      width: 370px;
    }
    @media screen and (max-width: 913px) {
      width: 346px;
    }
    @media screen and (max-width: 813px) {
      width: 297px;
    }
    @media screen and (max-width: 663px) {
      width: 246px;
    }
    @media screen and (max-width: 563px) {
      width: 513px;
    }
    @media screen and (max-width: 513px) {
      width: 463px;
    }
    @media screen and (max-width: 463px) {
      width: 413px;
    }
    @media screen and (max-width: 413px) {
      width: 363px;
    }
    @media screen and (max-width: 363px) {
      width: 313px;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
    }
  }
  &__lastgames {
    width: 573px;
    height: auto;
    @media screen and (max-width: 963px) {
      width: 543px;
    }
    @media screen and (max-width: 913px) {
      width: 516px;
    }
    @media screen and (max-width: 863px) {
      width: 466px;
    }
    @media screen and (max-width: 763px) {
      width: 416px;
    }
    @media screen and (max-width: 713px) {
      width: 366px;
    }
    @media screen and (max-width: 613px) {
      width: 316px;
    }
    @media screen and (max-width: 563px) {
      width: 513px;
    }
    @media screen and (max-width: 513px) {
      width: 463px;
    }
    @media screen and (max-width: 463px) {
      width: 413px;
    }
    @media screen and (max-width: 413px) {
      width: 363px;
    }
    @media screen and (max-width: 363px) {
      width: 313px;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
    }
  }
}
.betperesult {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden !important;
  margin-top: 40px;
  @media screen and (max-width: 963px) {
    width: 913px;
  }
  @media screen and (max-width: 913px) {
    width: 863px;
  }
  @media screen and (max-width: 863px) {
    width: 813px;
  }
  @media screen and (max-width: 813px) {
    width: 763px;
  }
  @media screen and (max-width: 763px) {
    width: 713px;
  }
  @media screen and (max-width: 713px) {
    width: 663px;
  }
  @media screen and (max-width: 663px) {
    width: 613px;
  }
  @media screen and (max-width: 613px) {
    width: 563px;
  }
  @media screen and (max-width: 563px) {
    width: 513px;
  }
  @media screen and (max-width: 513px) {
    width: 463px;
  }
  @media screen and (max-width: 463px) {
    width: 413px;
  }
  @media screen and (max-width: 413px) {
    width: 363px;
  }
  @media screen and (max-width: 363px) {
    width: 313px;
  }
  @media screen and (max-width: 313px) {
    width: 280px;
  }
}
.betperesult-general {
  width: 100%;
  height: auto;
}
.betperesult-facetoface {
  width: 100%;
  height: auto;
  //margin-top: 1rem;
  // background: rgb(226, 226, 226);
}
.betperesult-players {
  width: 100%;
  height: auto;
  //margin-top: 1rem;
}

.bet-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.2rem 0;
}
</style>
